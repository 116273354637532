import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SHOW_LOGIN, CLIP_OFFER } from '../redux/reducers/offer';
import { SET_USER } from '../redux/reducers/user';
import store from '../redux';

function LoginModal(props) {
  const dispatch = useDispatch();
  const {
    offer: { show_login: show, mdid },
    config: {
      retailer: { id: retailerId },
    },
  } = useSelector((state) => state);
  const handleClose = () => {
    setLoginError(false);
    dispatch(SHOW_LOGIN({ show: false }));
  };

  let rememberUser = useRef(false);
  let [username, setUserName] = useState();
  let [loginError, setLoginError] = useState(false);
  let [isDisabled, setEnableBtn] = useState(true);
  let mpidbyEnv = store.getState().config.myEnv;
  const mpid = store.getState().config.retailer.mpid[mpidbyEnv];
  let funnel = process.env.REACT_APP_FUNNEL ? process.env.REACT_APP_FUNNEL : 'n/a';
  let channel = process.env.REACT_APP_CHANNEL ? process.env.REACT_APP_CHANNEL : 'n/a';
  // let marketingEvent = process.env.REACT_APP_MARKETING_EVENT ? process.env.REACT_APP_MARKETING_EVENT : 'n/a';

  function checkModalValidation(e) {
    const userInput = e.target.value;
    const regexp = /^[0-9]{10}$/;
    const splChar = /[-() ]/;
    //Checking if input field is empty
    if (userInput === '') {
      setLoginError(false);
      setUserName(userInput);
      setEnableBtn(true);
    }
    // Checking for valid numbers
    else if (regexp.test(userInput)) {
      setLoginError(false);
      setUserName(userInput);
      setEnableBtn(false);
    }
    // Checking for spaces, parenthesises, or hyphens
    else if (splChar.test(userInput)) {
      setLoginError('Must be digits');
    } else {
      setLoginError('Member Id must be 10 digit numbers');
      setEnableBtn(true);
    }
  }

  function handleValidation() {
    if (username) {
      const options = {
        endpoint: 'offers/load',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-retailer': retailerId,
          Origin: window.location.origin,
        },
        body: { 
          username: username, 
          id: mdid,
          mediaPropertyId: mpid,
          channel: channel, 
          funnel: funnel, 
          // marketingEvent: marketingEvent
        },
      };

      dispatch(CLIP_OFFER(options)).then((resp) => {
        if (resp.payload.success) {
          dispatch(SET_USER({ state: 2, username: username }));
          if (rememberUser.current.checked) {
            saveToStorage(username, JSON.stringify(resp.payload.mdid));
          }
          // trackLoginEvent();
          handleClose();
        } else {
          setLoginError(resp.payload.errorMsg);
        }
      });
    } else {
      setLoginError('Member Id required');
    }
  }
  function saveToStorage(username, mdid) {
    const obj = { username: username, myIds: mdid };
    localStorage.setItem('pbyt', JSON.stringify(obj));
  }
  function RenderLoginError() {
    if (loginError) {
      return <div className="error text-danger">{loginError}</div>;
    }
    return '';
  }
  function trackLoginEvent (){
    window.gtag('event', 'account_login', { 
      retailer_id: "Publix"
    });
  }
  if (show) {
    const {
      REACT_APP_COLOR_PRIMARY: primary,
      REACT_APP_COLOR_SECONDARY: secondary,
      REACT_APP_COLOR_LINKS: links,
    } = process.env;
    return (
      <div
        className="modal fade show"
        id="loginModal"
        style={{ display: show ? 'block' : 'none' }}
        role="dialog"
      >
        <div
          className="modal-dialog"
          role="document"
          style={{ maxWidth: '800px' }}
        >
          <div className="modal-content text-justify">
            <div className="modal-header">
              <div className="modal-title">
                <h4>Add your Publix Digital Coupon Member ID</h4>
              </div>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <label htmlFor="phone">Phone Number:</label>
                  <input
                    type="text"
                    onChange={(e) => {
                      checkModalValidation(e);
                    }}
                    className="form-control"
                  />
                  <div className="form-check">
                    <input
                      type="checkbox"
                      ref={rememberUser}
                      className="form-check-input"
                      id="formLogin"
                    />
                    <label htmlFor="formLogin" className="form-check-label">
                      Remember me on this computer
                    </label>
                  </div>
                  <small className="text-muted">
                    Your Publix Digital Coupon Member ID is your phone number.
                    No spaces, parenthesises, or hyphens can be used when
                    entering your Member ID. If you don't have a Digtal Coupons
                    Account,{' '}
                    <a
                      style={{ color: links, fontWeight: '700' }}
                      href="https://www.publix.com/myaccount/register"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      click here
                    </a>
                    &nbsp;on the web to create one.
                  </small>
                  <RenderLoginError />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                style={{ color: primary }}
                onClick={handleClose}
                variant="link"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                disabled={isDisabled}
                style={
                  isDisabled
                    ? { backgroundColor: secondary }
                    : { backgroundColor: primary }
                }
                onClick={handleValidation}
              >
                LOGIN
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return '';
}
export default LoginModal;
