import React, {useEffect} from 'react';
import {useParams} from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';
import FontAwesome from "../common/FontAwesome";
import { useHistory } from "react-router-dom";

function RecipePage(props) {    
    const { recipeName } = useParams();
    const recipeTitle = recipeName.replace(/-/g,' ');
    const recipeList = [];
    const url = window.location.href;

    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])
    
    props.recipes.recipeSection.map((obj) => {
        return obj.sectionItems.map((items) =>{
          return recipeList.push(items)
        })       
    })
  
    const recipeStlyes = props.recipes.customStyles ? 'recipeDetail custom':'recipeDetail default';
    const recipeItem = recipeList.find(obj=>obj.title === recipeTitle);
    const a2c = recipeItem.card.a2c;
    const path = '/images/'+process.env.REACT_APP_BRAND_NAME+'/'+recipeItem.recipeDetail.headerImg;

    const headerStyle = {
      backgroundImage: "url(" + path + ")"
    }

    function mediaLink(type) {
      switch (type) {
        case 'pinterest':
          return `https://www.pinterest.com/pin/create/button/?url=${url}&media=${path}&description=${encodeURIComponent(
            recipeTitle,
          )}`;
        case 'facebook':
          return `https://www.facebook.com/share.php?u=${url}&title=${encodeURIComponent(
            recipeTitle,
          )}`;
        case 'twitter':
          return `http://twitter.com/share?text=${encodeURIComponent(
            recipeTitle,
          )}&url=${url}`;
        case 'envelope':
          return `mailto:?subject=I think you will enjoy this recipe!&body=Here is a recipe that I know you'll love: ${encodeURIComponent(recipeTitle)} - ${url}`;          
          default:
          break;
      }
    }
    function printPage(){
      window.print();
      // trackShare('print');
    }
    function trackAddToCartEvent(){
      window.gtag('event', 'addtocartclick', { 
        product: recipeTitle
      });
    }
    function trackShare(e){
      const type = e.currentTarget.getAttribute("data-value")
      window.gtag('event', 'trackshare', { 
        mediaType: type,
        recipeTitle: recipeTitle
      });
    }
    const history = useHistory()
    const goBack = () => {
      history.goBack()
    }

    return (
      <div className={recipeStlyes}>
        <div className="header">
          <div className="headerImage" style={headerStyle} >
          <div className='backButton' onClick={goBack}>
            <FontAwesome type='arrow-left'/>
          </div>
          <img className="print-only" src={path} alt={recipeTitle}/>
          </div>
        </div>
        <div className="subheader">
          {recipeItem.recipeDetail.border &&
          <div className='border'>
            <img src={'/images/'+process.env.REACT_APP_BRAND_NAME+'/'+recipeItem.recipeDetail.border} alt=""/>
          </div>
          }
          
          <div className='subheaderContent'>
            <div className='social'>
              <h2 className='title'>{recipeTitle}</h2>
              <h3>{recipeItem.recipeDetail.subheader}</h3>
                <div className='socialIcons'>
                  {['facebook','twitter','pinterest','envelope'].map((social, i) => {
                    let socialImage = '/images/'+process.env.REACT_APP_BRAND_NAME+'/recipes/social/'+social+'.png';
                      return (
                        <a
                          href={mediaLink(social)}
                          onClick={trackShare}
                          data-value={social}
                          target="_blank"
                          className={`btn-social `+ social}
                          key={i}
                          rel="noopener noreferrer"
                        >
                          {props.recipes.customStyles && 
                            <img src={socialImage} alt={social}/>
                          }
                          {!props.recipes.customStyles &&
                            <FontAwesome type={social} />
                          }
                        </a>
                      );
                  })}
                  <p
                    onClick={printPage}
                    data-value='print'
                    target="_blank"
                    className="btn-social"
                    rel="noopener noreferrer"
                  >
                    {props.recipes.customStyles && 
                      <img src={'/images/'+process.env.REACT_APP_BRAND_NAME+'/recipes/social/print.png'} alt='print'/>
                    }
                    {!props.recipes.customStyles &&
                      <FontAwesome type='print' />
                    }
                  </p>
                </div>
            </div>
            <div className='times'>
              <div className=''>
                {recipeItem.recipeDetail.times.prep &&
                  <img src={'/images/'+process.env.REACT_APP_BRAND_NAME+'/recipes/'+ (recipeItem.recipeDetail.times.prep)} alt="Prep Time"/>
                }
                {recipeItem.recipeDetail.times.prepText &&
                  <p>Prep Time<br></br>
                  <strong>{(recipeItem.recipeDetail.times.prepText)}</strong></p>
                }
              </div>
              <div className=''>
                {recipeItem.recipeDetail.times.total &&
                  <img src={'/images/'+process.env.REACT_APP_BRAND_NAME+'/recipes/'+ (recipeItem.recipeDetail.times.total)} alt="Total Time"/>
                }
                {recipeItem.recipeDetail.times.totalText &&
                  <p>Total Time<br></br>
                  <strong>{(recipeItem.recipeDetail.times.totalText)}</strong></p>
                }
              </div>
              <div className=''>
                {recipeItem.recipeDetail.times.servings &&
                  <img src={'/images/'+process.env.REACT_APP_BRAND_NAME+'/recipes/'+ (recipeItem.recipeDetail.times.servings)} alt="Serving Size"/>
                }
                {recipeItem.recipeDetail.times.servingsText &&
                  <p>Servings<br></br>
                  <strong>{(recipeItem.recipeDetail.times.servingsText)}</strong></p>
                }
              </div>
            </div>
          </div>
          {recipeItem.recipeDetail.border &&
          <div className='border'>
            <img src={'/images/'+process.env.REACT_APP_BRAND_NAME+'/'+recipeItem.recipeDetail.border} alt=""/>
          </div>
          }
        </div>
        <div className="detail">
            <div className="ingredients">
                <h3>Ingredients</h3>
                <div className='a2c-block'>
                  <p>Want to buy theses ingredients?</p>
                  <button className="cart-btn" >
                    <a 
                        href={a2c} 
                        onClick={trackAddToCartEvent} 
                        target="_blank" 
                        rel="noopener noreferrer"
                    >Add Items to Cart</a>
                  </button>
                </div>
                {ReactHtmlParser(recipeItem.recipeDetail.ingredients)}
            </div>
            <div className="directions">
                <h3>Directions</h3>
                {ReactHtmlParser(recipeItem.recipeDetail.instructions)}
            </div>
        </div>
        {props.recipes.programLogo &&
          <img src={'/images/'+process.env.REACT_APP_BRAND_NAME+'/'+props.recipes.programLogo} alt={process.env.REACT_APP_BRAND_NAME} className="logo"/>
        }
      </div>
    );
}
export default RecipePage;
